<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        height="50"
      >
        <v-toolbar-title>Locations</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          small
          to="/locations/create"
        >
          <v-icon
            left
            dark
          >mdi-plus</v-icon>Add Locations
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="locations"
            >
              <template v-slot:item.Location="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/locations/${item.id}`"
                >{{ item.Location }}</v-btn>
              </template>
              <template v-slot:item.OcrName="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/locations/${item.id}`"
                >{{ item.Location }}</v-btn>
              </template>

              <template v-slot:item.created_at="{ item }">{{ item.created_at | moment("Do MMM YYYY")}}</template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search: "",
    record: {},
    locations: [],
    headers: [
      { text: "Code", value: "id" },
      { text: "Location Name", value: "Location" },
      { text: "Created On", value: "created_at" },
    ],
  }),
  methods: {
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/locations`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.locations = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>